import InputText from '@/libs/useful/InputText'
import { __ } from '@/libs/utilities'
import { useEffect, useState } from 'react'
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'

export const URL_SEPARATOR = "$@$@$@$@$@$@$@"
 
export const getURL = (url: string) => {
    return url?.split(URL_SEPARATOR) || ["", ""]
}

const URL = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<URLEnabled {...props} />}
        desabledForm={<URLDesabled {...props} />}
    />
} 
export default URL 

let _title: string 
let _link :string
const URLEnabled = ( props: IScalarForm ): JSX.Element => { 
    const [title, setTitle] = useState<string>("")
    const [link, setLink] = useState<string>("") 
    const [tick, setTick] = useState<boolean>(false) 
    useEffect(() => {
        const val: string[] = props.value?.split(URL_SEPARATOR) || ["", ""]
        setTitle( val[1] || "" )
        setLink(  val[0] || "" )
    }, [])
    const getURL = () => { 
        return !!(_link || _title) ? [ _link, _title ].join(URL_SEPARATOR) : ""
    }
    const onChangeURL = (evt: any) => { 
        const value = evt.currentTarget.value
        setLink(value) 
        _link = value
        onChange()   
    }
    const onChangeTitle = (evt: any) => { 
        const value = evt.currentTarget.value
        setTitle( value )  
        _title = value
        onChange()   
    }
    const onCBURL = ( data: string | null ) => {
        if(!data) return
        setLink( data ) 
        _link = data
        onChange()
    }
    const onCBTitle = ( data: string | null ) => { 
        if(!data) return
        setTitle( data )
        _title = data
        setTick(!tick)
        onChange()
    }
    const onChange = () => {
        if( props.onChange )
        { 
            const oCh = props.onChange 
            const url = getURL() 
            const field = props.field

            setTimeout(() => {
                console.log( url )
                oCh( getURL(), field, "" )
            }, 1300)
        }
    }
    
    return <div className='w-100'> 
        <div className=" d-flex justify-content-center flex-column my-2 p-0 w-100">
            <div className="w-100 d-flex align-items-center">
                <i className="fas fa-pen-nib" />
                <InputText
                    className="form-control input dark"
                    value={ title || ""}
                    placeholder={ __( "write label" ) }
                    onChange={ onChangeTitle }
                    onPaste={ onCBTitle }
                />
            </div>
            <div className="w-100 d-flex align-items-center">
                <i className="fas fa-desktop" />
                <InputText 
                    className="form-control input dark"
                    value={ link || "" }
                    placeholder={__("write link")}
                    onChange={ onChangeURL }
                    onPaste={onCBURL}
                />
            </div>
        </div>
    
    </div>
} 
 
const URLDesabled = ( props: IScalarForm ): JSX.Element | false => {
    const [title, setTitle] = useState<string>("")
    const [link, setLink] = useState<string>("")
    useEffect(() => { 
        const val: string[] = props.value?.split(URL_SEPARATOR) || ["", ""]
        setTitle(val[1])
        setLink( val[0]) 
    }, []) 

    const onClick = (evt: any) => {
        evt.stopPropagation()
        evt.preventDefault() 
        window.open(link,'_blank')
    }
    return !!link && <div className='d-flex align-items-center w-100'>
        <div className='lead opacity_75'> 
            <i className="fas fa-link mr-2"></i>
		</div>
        <a 
            href={link} 
            target="_blank" 
            rel="noreferrer" 
            className="text-overflow bp3-text-overflow-ellipsis"
            onClick={onClick}
        >            
            {`${ title || "link"} `}
        </a>

        
    </div> 
} 

// https://searchengines.guru/ru/forum/106515