import { ID } from "@/libs/interfaces/layouts"
import { __ } from "@/libs/utilities"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { Button, Collapse } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import getModeratorAvatar from "../../utils/getModeratorAvatar"
import TrackLabel from "../TrackLabel"
import TrackVKLink from "../../utils/TrackVKLink"
import TrackVideo from "../../utils/TrackVideo"
import { trimContent } from "../../about/FestTrackCard"
import TrackEditor from "./TrackEditor"
import TrackLastAdlabel from "../../utils/TrackLastAdlabel"
import { IAds } from "@/modules/pe-topos-module/data/interfaces"
import TrackAttachmentlabel from "../../utils/TrackAttachmentlabel"
import { useIsTrackModerator } from "@/modules/pe-fest-module/hooks/track"
import TrackAttacmentAdd from "./TrackAttacmentAdd"
import TrackAttachmentsArchive from "./TrackAttachmentsArchive"

/* Protopia Ecosystem component */
export interface ITrackTaxProps {
    filter: ID[]
}

const TrackTax = (props: ITrackTaxProps): JSX.Element => { 
    const [current, setCurrent] = useState<ITrack | null>( null )
    const PEFestTrack: ITrack = useDataStore((state: any) => state.PEFestTrack )
    useEffect(() => {
        if( props.filter[0] === -1 ) {
            setCurrent( null )
        }
        else {
            setCurrent( 
                PEFestTrack?.filter((track: ITrack) => track.id === props.filter[0]?.toString() )[0] || {}
            )
        }
    }, [ props.filter, PEFestTrack ])
    const onCurrent = (track: ITrack ) => {
        if( current ) {
            setCurrent( null ) 
        }
        else {
            setCurrent( track ) 
        }
    }
    return <>
        {/* <Collapse 
            className=' w-100 d-flex short-container flex-wrap ' 
            isOpen={ !!current && props.filter[0] !== -1 } 
            transitionDuration={ 1000 } 
        >
        {
                props.filter.map(f => <TrackButtonTax trackId={ f } festId={ festId } onTrack={ onCurrent } />)
        }
        </Collapse> */}
        <Collapse 
            className=' container-float' 
            isOpen={ !!current && props.filter[0] !== -1 } 
            transitionDuration={ 1000 } 
        >
            <CurrentTrackTax track={current} />
        </Collapse>    
    </>
}
export default TrackTax

interface ITrackTaxButtonProps {
    trackId: ID
    festId: ID
    onTrack: (track:ITrack ) => void
} 
const TrackButtonTax = ({trackId, festId, onTrack}: ITrackTaxButtonProps): JSX.Element => {
    const [track, setTrack] = useState<ITrack>({} as ITrack)
    useEffect(() => {
        setTrack(
            useDataStore.getState().PEFestTrack?.filter((track: ITrack) => track.id === trackId)[0] || {}         
        )
    }, [ trackId, festId ])
    return <Button minimal className=' m-1' onClick={ () => onTrack(track) } > 
        <TrackLabel className="flex-centered " track={ track } /> 
    </Button>
} 
 
interface ICurrentTrackTaxProps {
   track: ITrack | null
}
const CurrentTrackTax = ({track, ...props}: ICurrentTrackTaxProps): JSX.Element => { 
    const[isOpen, setOpen] = useState(false)
    const trimmedContent = trimContent( track?.content || "", 30 ) 
    
    return <div className='w-100'> 
        <div className=" light-colored  m-0 flex-row">
            <div className="container d-flex flex-md-row flex-column">
                <div 
                    className="w-md-33 flex-grow-100 position-relative overflow-hidden pointer "
                    style={{
                        backgroundImage:`url(${ track?.illustration })`, 
                        minHeight: 250,
                        backgroundSize: 'cover',
                        backgroundPosition: "center"
                    }}
                    onClick={() => setOpen( !isOpen )}
                >
                    <TrackVKLink item={track || {} as ITrack} />
                    <TrackVideo  item={track || {} as ITrack} />
                    {
                        track?.is_closed
                            &&
                            <span 
                                style={{
                                position:"absolute",
                                top: 80,
                                left: 50,
                                width:"120%",
                                transform: "rotate(15deg)",
                                marginLeft: -90, 
                                height: 40, 
                                display:"flex", 
                                alignItems:"center", 
                                justifyContent:"center", 
                                padding:"2px 12px", 
                                backgroundColor:"red",
                                whiteSpace : "nowrap",
                                fontSize: 22,
                                textTransform:"uppercase",
                                color:"#FFF"
                                }}
                            >
                                { __( "Register closed!" ) }
                            </span> 
                    }
                </div>
                <div
                    className="w-md-66 flex-grow-100 p-0 " 
                >
                    <div className="lead p-3 align-items-center ">
                        <img src={ track?.thumbnail } alt="" className='track-miniature'/> 
                        { track?.title }
                        <TrackEditor item={track} />
                    </div>
                    <div onClick={() => setOpen( !isOpen )} className="pointer">
                    {
                        trimmedContent.isTrim || track?.moderator?.user_descr?.length > 0
                            ?
                            <>
                                <Collapse isOpen={!isOpen} className={` ${ !isOpen ? "cclosed" : "" } `}>
                                <div className="px-4 pt-2 pb-2 flex-centered flex-column content-collapse">
                                    <div className="h-100 text-clip--transparent" dangerouslySetInnerHTML={{ __html: trimmedContent.content }} />
                                    
                                </div>                                               
                                </Collapse>
                                <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}> 
                                <div className="px-4 pt-4 pb-5 flex-centered flex-column content-collapse">
                                    <div 
                                    className="h-100" 
                                    dangerouslySetInnerHTML={{ __html: track?.content || "" }} 
                                    />
                                </div>
                                </Collapse>       
                            </>
                            : 
                            <>
                                <div className="px-4 py-2">
                                    { trimmedContent.content }
                                </div> 
                            </>
                    }
                    </div> 
                    {
                        track && getModeratorAvatar(track, true, Math.random(), "moderator")
                    }
                    {
                        track && getModeratorAvatar(track, true, Math.random(), "moderator2")
                    }
                    {/* <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}> 
                        <TrackLastAdlabel item={ track?.last_ad } track={ track } />                                          
                    </Collapse>
                    <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}> 
                        <div className="d-flex">
                            <TrackAttachmentlabel item={ track } />
                        </div>                             
                    </Collapse> */}
                </div>
                
            </div> 
        </div>
    </div>
} 