import { IGanre } from "@/modules/pe-fest-module/data/interfaces"

/* Protopia Ecosystem component */
export interface IGanreLabelProps {
   ganre: IGanre
   small?:boolean
}
const GanreLabel = ({ganre, small}: IGanreLabelProps): JSX.Element => {
    return <div className={`ganre-label ${ small ? "small" : "" }`} style={{backgroundColor: ganre.color?.toString() }} title={ ganre.title }>
        {
            !small && <img src={ganre.thumbnail} className="ganre-miniature m-0" alt="" />
        }
        <div className="pt-1 px-1 small text-center text-light" > 
            { ganre.title }
        </div>
    </div>
}
export default GanreLabel