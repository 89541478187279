import { getFeedRoute, getRoute } from "@/libs/layouts"
import { __cases } from "@/libs/utilities"
import { Link } from "react-router-dom"
import { IGanre } from "../../data/interfaces"


/* Protopia Ecosystem component */
export interface IFestGanreCardProps {
   item: IGanre
}
const FestGanreCard = (props: IFestGanreCardProps): JSX.Element => { 
    const routeSingle = getFeedRoute("PEFestProject") 
    const _url = routeSingle
        ? 
        `/${ getRoute(routeSingle)}?ganres=-1,${ props.item.id }` 
        : 
        "/" 
    return <div className='col-xl-3 col-lg-3 col-md-4 col-sm-4 col-6 mb-4'>
        <Link 
            className="card h-100 m-0 pb-3 position-relative pointer hover" 
            // onClick={onClick}
            to={ _url } 
        > 
            <div 
                className='track-icon position-relative mt-3' 
                style={{
                    backgroundImage: `url(${props.item.thumbnail})`, 
                    backgroundColor: props.item.color.toString() , 
                    top:0
                }} 
            />
            <div
                className=" d-flex flex-centered flex-column"
                title={ props.item.title }
                style={{
                height: 76,
                overflow: "hidden",
                padding: "0 1.25em",
                position: "relative",
                display: "table",
                paddingTop: 14
                }}
            >
                <h5 className="m-0 w-100 text-center " style={{ lineHeight: 1.0 }} >
                    { props.item.title }  
                </h5>
                <div>
                    <span className='mr-2'>{ props.item.count || 0 }</span>
                    <span>{__cases(props.item.count, "projects")}</span>
                </div>
            </div>
        </Link>
    </div>
}
export default FestGanreCard